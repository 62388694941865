import React from 'react';
import logo from './images/logo-nev.png';
import './App.css';
import Home from './Home';
import Gdpr from './Gdpr';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Nav} from 'react-bootstrap';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isNavExpanded: false
    };

    this.setIsNavExpanded = (isNavExpanded) => {
      this.setState({ isNavExpanded: isNavExpanded });
    }

    this.handleClick = (e) => {
      if (this.node.contains(e.target)) {
        // if clicked inside menu do something
      } else {
        // If clicked outside menu, close the navbar.
        this.setState({ isNavExpanded: false });
      }
    }
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

render() {
  return (
    <BrowserRouter>
      <div className="app">
        <div  id="rolam"/>
        <Navbar variant="dark"
              className="menu"
              expand="lg"
              sticky="top"
              collapseOnSelect
              onToggle={this.setIsNavExpanded}
              expanded={this.state.isNavExpanded}
              ref={node => this.node = node}>
            <Container>
              <Navbar.Brand className="white-text title" href="/"><img src={logo} className="navbar-logo" alt="navbar-logo" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="right"/>
              <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="mr-auto justify-content-end" style={{ width: "100%" }}>
                  <Nav.Link href="/#rolam">RÓLAM</Nav.Link>
                  <Nav.Link href="/#rendeles">RENDELÉS</Nav.Link>
                  <Nav.Link href="/#elerhetoseg">ELÉRHETŐSÉG</Nav.Link>
                  <Nav.Link href="/adatkezelesi-iranyelvek">ADATKEZELÉSI IRÁNYELVEK</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
        </Navbar>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/adatkezelesi-iranyelvek" component={Gdpr} />
        </Switch>
        <CookieConsent
         buttonText="Megértettem">
            A weboldal felhasználóbarát működése érdekében cookie-kat használunk. <a href="/adatkezelesi-iranyelvek">Bővebben</a>
        </CookieConsent>
      </div>
    </BrowserRouter>
  );
  }
};
