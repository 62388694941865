import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col} from 'react-bootstrap';
import { MdLocationOn } from "react-icons/md";
import { FaPhone } from "react-icons/fa";
import Iframe from 'react-iframe';
import galLaszlo from './images/gal_laszlo.jpg';

const Home = () => (
  <Container >
    <Row className="tp-col-l">
      <Col xs={12} className="cv">
        <img src={galLaszlo} className="galLaszlo" alt="galLaszlo" align="left"/>
          <h2>Nőgyógyász és Klinikai Onkológus Szakfőorvos</h2>
          <p>Orvosi diplomámat 1981-ben Budapesten a Semmelweis Orvostudományi Egyetemen szereztem.<br/>
          1985-ben Szülészet – Nőgyógyászatból, majd 1996-ban Klinikai Onkológiából szakvizsgáztam.<br/>
          1981-ben kezdtem dolgozni a Bugát Pál Kórház Szülészet-Nőgyógyászati osztályán, majd 1985-től az Onkológiai Szakrendelésen Főorvosként.</p>
          <p>Tagja vagyok a Magyar Nőorvos Társaság, a Magyar Onkológusok Társasága, a Magyar Nőgyógyász Onkológusok Társasága és a Magyar Klinikai Onkológiai Társaságnak.</p>
          <p>Szakterületeim: Nőgyógyászat, Klinikai Onkológia</p>
          <p>1994-ben nyitottam meg Nőgyógyászati Magánrendelésem, ahol az alábbi tevékenységeket végzem:</p>
          <ul>
            <li>Nőgyógyászati betegségek vizsgálata és kezelése, méhnyakrákszűrés (a legkorszerűbb folyadék alapú citológiai vizsgálat is adott)</li>
            <li>Hüvelyi fertőzések kivizsgálása és kezelése</li>
            <li>Emlőbetegségek vizsgálata és kezelése</li>
            <li>Bőrdaganatok szűrővizsgálata, terápiás tanácsadás</li>
            <li>Fogamzásgátlás, sürgősségi fogamzásgátlás</li>
            <li>Vérzészavarok, menstruációs problémák kivizsgálása, kezelése</li>
            <li>Családtervezés, meddőségi kivizsgálás</li>
            <li>HPV (humán papillómavírus) elleni védőoltás – méhnyakrák elleni védőoltás</li>
          </ul>
      </Col>
    </Row>
    <Row className="tp-col-l" id="rendeles">
      <Col xs={12} align="center">
        <hr/>
      </Col>
    </Row>
    <Row className="tp-col-l">
      <Col xs={12} md={6} lg={5} className="tp-col">
        <Row>
          <Col xs={12}>
            <h4>Rendelés:</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="tp-col">
            <Row>
              <Col xs={4}><h5>Kedd:</h5></Col>
              <Col xs={8} align="right"><h5>15:30 - 19:00</h5></Col>
            </Row>
            <Row>
              <Col xs={4}><h5>Csütörtök:</h5></Col>
              <Col xs={8} align="right"><h5>15:30 - 19:00</h5></Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="tp-col">
            <b>Vírushelyzetre való tekintettel szájmaszk viselése kötelező és telefonos időpont foglalás szükséges.</b>
          </Col>
        </Row>
        <Row className="tp-col-l" id="elerhetoseg">
          <Col xs={12} align="center">
            <hr/>
          </Col>
        </Row>
        <Row>
          <Col xs={12}  className="tp-col-l">
            <h4>Elérhetőség:</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="tp-col">
            <a href="tel:06309056890"><FaPhone size={26}/> +36 30/90-56-890</a>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="tp-col">
            <a href="https://www.google.hu/maps/place/Gy%C3%B6ngy%C3%B6s,+P%C3%BCsp%C3%B6ki+u.+19,+3200/@47.7772091,19.9168409,17z/data=!3m1!4b1!4m5!3m4!1s0x47405a09a4b7fd1d:0x68b60f815da6b9ff!8m2!3d47.7772091!4d19.9190296" target="_blank" rel="noopener noreferrer" ><MdLocationOn size={26}/> Gyöngyös, Püspöki utca 19</a>
          </Col>
        </Row>

      </Col>
      <Col xs={12} md={6} lg={7} className="tp-col">
        <Iframe width="100%" height="400" frameBorder="0" src="https://maps.google.com/maps?q=gy%C3%B6ngy%C3%B6s%20p%C3%BCsp%C3%B6ki%20utca%2019&t=&z=15&ie=UTF8&iwloc=&output=embed" />
      </Col>
    </Row>
  </Container>
);
export default Home;
